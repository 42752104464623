import { template as template_5df312f379a6425eac573cda2b4f59e5 } from "@ember/template-compiler";
const FKLabel = template_5df312f379a6425eac573cda2b4f59e5(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
